<template>
  <div :class="`${!isMobileDevice ? 'player-container' : 'player-container-mobile'}`">
    <div class="flex justify-center">
      <ImageResize class="h-50 mx-auto only-xl:h-80 object-contain" :src="player.img" type="player_modal" />
    </div>
    <div :class="`${!isMobileDevice ? 'sell-form' : 'sell-form-mobile'} flex flex-col flex-1 px-11-7 pb-7-8 rounded-24 xl:rounded-b-none md:px-4`">
      <div class="text-center text-xl24 font-bold">{{$t('player.sell_player_collection')}}</div>

      <div class="flex flex-col mt-7-5">
        <div class="flex justify-between items-center">
          <div class="text-xl30 font-bold sm:text-xl20">{{$t('player.you_sell')}} (%)</div>
          <div class="w-38">
            <input
              @keypress="preventNonNumericalInput"
              class="v8 no-arrows"
              data-testid="input-amountPct"
              placeholder="0%"
              type="number"
              v-model="amountPct"
              v-on:input="debouncer()"
            />
          </div>
        </div>
        <div class="flex justify-between items-center font-semibold mt-5">
          <div class="opacity-20">
            {{$t('player.available_balance_to_sell')}}
          </div>
          ${{numberFormat(portfolioValue)}}
        </div>
      </div>

      <div class="flex mt-5">
        <div
          @click="selectAmount('5')"
          :class="`selector ${Number(amountPct) === 5 ? '' : 'text-text-inactive'} text-xl24 hover:opacity-80 sm:text-n-lg`"
        >
          5%
        </div>
        <div
          @click="selectAmount('25')"
          :class="`selector ml-2-2 ${Number(amountPct) === 25 ? '' : 'text-text-inactive'} text-xl24 hover:opacity-80 sm:text-n-lg`"
        >
          25%
        </div>
        <div
          @click="selectAmount('50')"
          :class="`selector ml-2-2 ${Number(amountPct) === 50 ? '' : 'text-text-inactive'} text-xl24 hover:opacity-80 sm:text-n-lg`"
        >
          50%
        </div>
        <div
          @click="selectAmount('100')"
          :class="`selector ml-2-2 ${Number(amountPct) === 100 ? '' : 'text-text-inactive'} text-xl24 hover:opacity-80 sm:text-n-lg`"
        >
          100%
        </div>
      </div>

      <div class="flex justify-between items-center font-semibold mt-5">
        <div class="text-xl30 font-bold sm:text-xl20">{{$t('player.you_receive')}}</div>
        <div class="text-xl30 font-bold sm:text-xl20">${{numberFormat(receiveAmount)}}</div>
      </div>

      <PlayerCardsList
        class="mt-7-8"
        :isSelling="true"
        :player="player"
        :amountPct="amountPct"
        :receiveAmount="receiveAmount"
        :cards="cards"
      />
      
      <div class="flex flex-col mt-5">
        <div class="flex justify-between items-center font-semibold mt-5">
          <div>{{$t('player.trade_fee')}}</div>
          ${{numberFormat(amountUsdFee)}}
        </div>
      </div>

      <div class="mt-auto">
        <ButtonV2
          @onClick="confirmSell"
          testId="btn-sell-collection"
          :inactive="isLoading || !amountPct || amountPct < 0.01"
          :label="$t('player.sell_player_collection')"
          class="mt-5"
          wide
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { debounce } from 'vue-debounce';

  import PlayerCardsList from '@/components/player/PlayerCardsList';
  import { ImageResize } from '@/components/misc';
  import ButtonV2 from '@/stories/misc/ButtonV2';
  import PlayerSellDistribution from '@/graphql/queries/PlayerSellDistribution.gql';
  import SELL_PLAYER from '@/graphql/mutations/SellPlayer.gql';

  export default {
    name: 'SellPlayer',
    components: {
      PlayerCardsList,
      ImageResize,
      ButtonV2,
    },
    data() {
      return {
        amountPct: '',
        amountUsdFee: 0,
        sellDistributionId: '',
        cards: this.initialCards,
        receiveAmount: 0,
        isLoading: false,
      };
    },

    async mounted() {
      this.debouncer = debounce(() => {
        this.playerSellDistribution();
      }, 300);

      const player = this.$store.state.api['player'];

      await this.$store.dispatch('events/track', {
        event: 'PLAYER_CHECKOUT_VIEWED',
        variables: {
          id: player.id,
          id_hr: player.id_hr,
          sport: player.sport ? player.sport.id_hr : '',
          team: player.team ? player.team.name : '',
          total_value: player.total_value,
          asset_count: player.asset_count,
          change_24h: player.change_24h,
          change_24h_pct: player.change_24h_pct,
          transaction_type: 'sell',
        },
      });

      this.$root.$on('confirm-sell-player', () => {
        this.sell();
      });
    },

    beforeDestroy() {
      this.$root.$off('confirm-sell-player');
    },

    props: {
      player: {
        type: Object,
        required: true,
      },
      initialCards: {
        type: Array,
        required: true,
      },
      portfolioValue: {
        type: String,
        required: true,
      },
    },
    methods: {
      selectAmount(amount) {
        this.amountPct = amount;
        this.playerSellDistribution();
      },

      async playerSellDistribution() {
        if (!this.amountPct) {
          return;
        }

        this.isLoading = true;

        try {
          const response = await this.$apollo.query({
            query: PlayerSellDistribution,
            variables: {
              player_id_hr: this.$route.params['id_hr'], 
              percentage: this.amountPct / 100,
            },
            fetchPolicy: 'no-cache',
          });

          const sell_distribution = response.data.player_sell_distribution;

          this.cards = sell_distribution.assets;
          this.receiveAmount = this.$big(sell_distribution.target_amount_usd).minus(sell_distribution.target_amount_usd_fee).toNumber();
          this.amountUsdFee = this.$big(sell_distribution.target_amount_usd_fee).toNumber();
          this.sellDistributionId = sell_distribution.id;
        } catch (err) {
          await this.showError(err);
        } finally {
          this.isLoading = false;
        }
      },

      confirmSell() {
        this.$store.commit('ui/showPrompt', {
          eventName: 'confirm-sell-player',
          title: this.$t('player.confirm_transaction'),
          message: this.$t('player.confirm_sell_player', { 
            amount: this.amountPct, 
            player: this.player.name
          })
        });
      },

      async sell() {
        this.isLoading = true;

        try {
          const player = this.$store.state.api['player'];

          await this.$store.dispatch('events/track', {
            event: 'PLAYER_CHECKOUT_SUBMITTED',
            variables: {
              id: player.id,
              id_hr: player.id_hr,
              sport: player.sport ? player.sport.id_hr : '',
              team: player.team ? player.team.name : '',
              total_value: player.total_value,
              asset_count: player.asset_count,
              change_24h: player.change_24h,
              change_24h_pct: player.change_24h_pct,
              amount: this.receiveAmount,
              transaction_type: 'sell',
            },
          });

          await this.$apollo.mutate({
            mutation: SELL_PLAYER,
            variables: {
              player_id_hr: this.$route.params['id_hr'], 
              percentage: this.amountPct / 100,
              sell_distribution_id: this.sellDistributionId,
            }
          });

          this.showModal('SellPlayerConfirmation', {
            player: this.player,
            receiveAmount: this.receiveAmount,
            amountUsdFee: this.amountUsdFee,
            cards: this.cards,
            customBackground: 'bg-background-primary',
          });
        } catch (err) {
          await this.showError(err);
        } finally {
          this.isLoading = false;
        }
      }
    }
  };
</script>

<style scoped>
.player-container {
  @apply pt-11-7 px-11-7 -mx-4 min-w-sm;
  margin-bottom: -1.12rem;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 20px;
  height: min(calc(100vh - 25px), 873px);
}
.player-container-mobile {
  @apply flex flex-1 flex-col pt-7 px-7 mt-auto;
  margin-bottom: -1.12rem;
  height: min(calc(100vh - 25px), 873px);
}
.sell-form {
  @apply bg-background-primary -mx-11-8 pt-7-8;
}
.sell-form-mobile {
  @apply bg-background-primary -mx-7 pt-4;
}
::-webkit-scrollbar-track {
  margin: 12px 0;
}
.selector {
  @apply flex justify-center items-center flex-1 font-semibold border border-border py-1 cursor-pointer;
  border-radius: 10px;
}
</style>
